footer {
	padding-top: 53px;
	background: url(../../img/footer-bg.jpg) center center repeat-x;
	background-size: auto 100%;

	a.logo {
		display: block;
		text-decoration: none;
		max-width: 200px;
		width: 100%;
		img {
			width: 100%;
		}
	}

	ul {
		@include reset_ul;
		@include flex-end;
		li {
			margin-left: 30px;
			padding-left: 30px;
			position: relative;
			a {
				font-size: 16px;
				text-transform: uppercase;
				color: $primary_color;
				text-decoration: none;
				font-weight: $fw-semibold;

				&:hover {
					text-decoration: none;
					&:hover {
						color: $red;
					}
				}
			}

			&::before {
				content: '';
				display: block;
				position: absolute;
				width: 2px;
				height: 16px;
				background-color: $primary_color;
				left: -1px;
				top: 4px;
			}

			&:first-child {
				margin-left: 0px;
				padding-left: 0px;
				&::before {
					display: none;
				}
			}
		}
	}

	.copyright {
		height: 50px;
		background-color: rgba(#b20000, 0.1);
		font-size: 16px;
		line-height: 1;
		margin-top: 40px;
	}
}

@include mq_down($lg) {
	footer ul li {
		margin-left: 20px;
		padding-left: 20px;
	}
}
// mq_down_lg

/* @include mq_down($md) {
	footer ul li a {
		font-size: 16px;
	}
	footer .copyright {
		font-size: 18px;
	}
}
// mq_down_md */

@include mq_down($md) {
	footer {
		padding-top: 30px;
	}
	footer a.logo {
		max-width: 140px;
		display: block;
		//margin: 0px auto 30px auto;
	}
	footer ul {
		@include flex-center;
	}
	footer ul li a {
		font-size: 14px;
	}
	footer ul li {
		margin-left: 10px;
		padding-left: 10px;
	}
	footer ul li::before {
		width: 1px;
		height: 14px;
		top: 5px;
	}
	footer .copyright {
		margin-top: 30px;
		font-size: 16px;
		line-height: 20px;
	}
}
// mq_down_md

@include mq_down($sm) {
	footer a.logo {
		margin: 0px auto 30px auto;
	}
}
// mq_down_sm
