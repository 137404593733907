.content.employer-database {
	ul.alphabets-index {
		@include reset_ul;
		@include flex-space-between;
		margin-bottom: 60px;
		border-bottom: 1px solid $red;
		font-family: $font2;
		font-size: 30px;
		text-transform: uppercase;
		li {
			a {
				color: #6d6d6d;
				text-decoration: none;
				display: block;
				padding-bottom: 24px;
				//border: 1px solid red;
				width: 30px;
				text-align: center;
				position: relative;
				margin: 0px auto;
			}

			&:hover,
			&.active {
				a {
					color: $red;
					&::after {
						content: '';
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 0 7.5px 10px 7.5px;
						border-color: transparent transparent $red transparent;
						display: block;
						position: absolute;
						bottom: 0px;
						left: 6px;
					}
				}
			}
		}
	}

	.col-md-6 {
		margin-bottom: 60px;
		.wrapper {
			position: relative;
			min-height: 150px;
			span.alpha-bg {
				font-size: 150px;
				line-height: 150px;
				color: $red;
				opacity: 0.18;
				font-weight: $fw-bold;
				position: absolute;
				left: 100px;
				bottom: 0px;
			}

			ul {
				@include reset_ul_block;
				li {
					margin-bottom: 5px;
					a {
						color: $primary_color;
						&:hover {
							text-decoration: none;
							color: $red;
						}
					}
				}
			}
		}
	}
}

@include mq_down($md) {
	.content.employer-database ul.alphabets-index {
		flex-wrap: wrap;
		justify-content: flex-start;
	}
	.content.employer-database ul.alphabets-index li {
		width: 7%;
	}
	.content.employer-database ul.alphabets-index li a {
		padding-bottom: 5px;
		margin-bottom: 24px;
	}
}
// mq_down_md

@include mq_down($sm) {
	.content.employer-database ul.alphabets-index li a {
		margin-bottom: 10px;
	}
	.content.employer-database ul.alphabets-index li {
		width: 10%;
	}
	.content.employer-database .col-md-6 {
		margin-bottom: 50px;
	}
	.content.employer-database .col-md-6 .wrapper span.alpha-bg {
		left: 120px;
	}
}
// mq_down_sm
