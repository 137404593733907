body {
	font-family: $font1;
	color: $primary_color;
	font-size: 16px;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-family: $font2;
}

*:focus,
a:focus,
a:active {
	outline: none;
	color: inherit;
	text-decoration: inherit;
}

br {
	font-size: inherit;
	line-height: inherit;
}

section {
	display: block;
}

img {
	max-width: 100%;
	height: auto;
}

a {
	text-decoration: none;
	@include transition;
}

i {
	color: inherit;
	font-size: inherit;
}

strong {
	font-size: inherit;
	line-height: inherit;
	font-weight: $fw-bold;
}

iframe {
	max-width: 100%;
}

svg {
	display: inline-block;
}

/* The message box is shown when the user clicks on the password field */
#pass_message {
	/* display:none; */
	background: rgba(#f3f3f3, 0);
	color: #000;
	position: relative;
	padding: 0px;
	margin-top: 10px;
	/* display: flex;
	justify-content: flex-start;
	flex-wrap: wrap; */
	div {
		width: 50%;
		float: left;
	}
}

#pass_message {
	display: none;
}
#pass_message div {
	font-size: 12px;
	position: relative;
	//width: 50%;
	margin-bottom: 5px;
}

/* Add a green text color and a checkmark when the requirements are right */
.valid {
	color: green;
}
.valid b {
	font-weight: bold;
	color: inherit;
}

.valid:before {
	position: relative;
	left: 0px;
	content: '\2714';
	margin-right: 10px;
}

/* Add a red text color and an "x" icon when the requirements are wrong */
.invalid {
	color: red;
}
.invalid b {
	font-weight: bold;
	color: inherit;
}

.invalid:before {
	position: relative;
	left: 0px;
	content: '\00D7';
	margin-right: 10px;
}

@include mq_down($sm) {
	#pass_message div {
		width: 100%;
		float: none;
	}
}
// mq_down_sm
