input,
button,
select,
textarea,
.form-control {
	font-family: $font1;
	line-height: normal;
}

button,
input[type='submit'] {
	@include transition;
}

.form-control {
	@include border-radius(0px);
	height: 48px;
	border: none;
	border: 1px solid #b6b6b6;

	&::placeholder {
		font-style: italic;
	}

	&:focus {
		border-color: #000;
		outline: 0;
		box-shadow: none;
	}

	&.wpcf7-not-valid {
		border-color: red;

		& + .wpcf7-not-valid-tip {
			text-align: left;
		}
	}
}

textarea.form-control {
	height: auto;
}

.form-group {
	margin-bottom: 25px;
}

.the-button,
a.the-button,
[type='button'].the-button,
[type='reset'].the-button,
[type='submit'].the-button,
button.the-button {
	@include reset_button;
	display: inline-block;
	height: 48px;
	line-height: 48px;
	border: 2px solid $orange;
	padding: 0px 20px;

	text-decoration: none;
	background: $orange;
	color: #fff;
	font-family: $font2;
	font-size: 32px;

	&:hover {
		background: transparent;
		color: $red;
	}

	&.lined {
		background: transparent;
		color: $red;

		&:hover {
			background: $orange;
			color: #fff;
		}
	}
}

@include mq_down($lg) {
	.the-button,
	a.the-button,
	[type='button'].the-button,
	[type='reset'].the-button,
	[type='submit'].the-button,
	button.the-button {
		font-size: 28px;
	}
}
// mq_down_lg

@include mq_down($sm) {
	.the-button,
	a.the-button,
	[type='button'].the-button,
	[type='reset'].the-button,
	[type='submit'].the-button,
	button.the-button {
		font-size: 24px;
	}
}
// mq_down_sm
