.banner + .red-box-search-result {
	margin-top: -90px;
}

.red-box-search-result {
	.the-label {
		font-size: 24px;
		font-family: $font2;
		text-transform: uppercase;
	}
	.the-value {
		font-size: 24px;
		font-weight: $fw-light;
	}
}

.content.job-details {
	padding-bottom: 0px;
	.the-header {
		border-bottom: 1px solid #78747c;
		padding-bottom: 30px;
		margin-bottom: 50px;

		.section-title {
			margin-bottom: 30px;
		}

		.company-logo {
			max-width: 300px;
			width: 100%;
			img {
				width: 100%;
			}
		}
		a.the-button {
			&.no-margin-top {
				margin-top: 0px;
			}
		}
	}

	.typography {
		padding-bottom: 40px;
		h3 {
			color: $primary_color;
			font-weight: $fw-bold;
		}

		h4 {
			color: $red;
		}

		ul {
			li {
			}
		}
	}

	.the-footer {
		background-color: #f6f4f4;
		padding: 45px 0px;
		.section-title {
			.text2 {
				font-size: 30px;
				padding: 8px 15px 4px 15px;
			}
		}

		p {
			font-size: 18px;
		}
	}
}

@include mq_down($md) {
	.banner + .red-box-search-result {
		margin-top: -50px;
	}

	.red-box-search-result .the-value {
		font-size: 18px;
	}

	.content.job-details .the-header .company-logo {
		max-width: 300px;
	}

	.content.job-details .the-header {
		margin-bottom: 40px;
		padding-bottom: 40px;
	}
}
// mq_down_md

@include mq_down($sm) {
	.banner + .red-box-search-result {
		margin-top: -50px;
	}

	.red-box-search-result .col-md-4 {
		margin-bottom: 20px;
		&:last-child {
			margin-bottom: 0px;
		}
	}

	.content.job-details .the-header .company-logo {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	.content.job-details .the-header .the-button {
		width: 100%;
		max-width: 300px;
		display: block;
		margin: 30px auto 0px auto;
	}
}
// mq_down_sm
