.banner {
	position: relative;
	img.bg {
		width: 100%;
		height: 617px;
		object-fit: cover;
		object-position: center;
	}
	.caption {
		position: absolute;
		left: 0px;
		bottom: 0px;
		width: 100%;
		z-index: 1;
		padding-bottom: 64px;
		h1.the-tagline {
			font-size: 64px;
			line-height: 64px;
			margin-bottom: 90px;
			text-transform: uppercase;
			color: $purple;
			img {
				margin-left: 30px;
			}
		}
		.search-box {
			.the-title {
				font-family: $font2;
				font-size: 36px;
				line-height: 36px;
				letter-spacing: 1px;
				text-transform: uppercase;
				color: $purple;
				margin-bottom: 26px;
			}
			form {
				width: 100%;
				input[type='text'] {
					border: 2px solid $red;
					height: 43px;
					flex: 1;
					padding: 0px 20px;

					&::placeholder {
						font-style: italic;
					}
				}
				input[type='submit'] {
					@include reset_button;
					background: $red;
					height: 43px;
					line-height: 43px;
					font-family: $font2;
					font-size: 26px;
					text-transform: uppercase;
					color: #fff;
					width: 202px;

					&:hover {
						background: darken($red, 10%);
					}
				}
			}
		}
	}

	&.inner {
		margin-bottom: 90px;
		img.bg {
			height: 386px;
		}
		.caption h1.the-tagline {
			margin-bottom: 0px;
		}
	}
}

@include mq_down($lg) {
	.banner .caption h1.the-tagline {
		font-size: 54px;
		line-height: 54px;
		margin-bottom: 70px;
	}
	.banner .caption h1.the-tagline img {
		width: 180px;
	}
}
// mq_down_lg

@include mq_down($md) {
	.banner img.bg {
		height: 500px;
	}
	.banner .caption h1.the-tagline {
		font-size: 44px;
		line-height: 44px;
		margin-bottom: 60px;
	}
	.banner .caption h1.the-tagline img {
		width: 140px;
		margin-left: 20px;
	}

	.banner .caption .search-box .the-title {
		font-size: 32px;
		line-height: 32px;
		margin-bottom: 20px;
	}

	.banner.inner img.bg {
		height: 300px;
	}
	.banner.inner {
		margin-bottom: 50px;
	}
}
// mq_down_md

@include mq_down($sm) {
	.banner img.bg {
		height: 400px;
	}
	.banner .caption h1.the-tagline {
		font-size: 32px;
		line-height: 32px;
		margin-bottom: 50px;
	}
	.banner .caption {
		padding-bottom: 30px;
	}
	.banner .caption h1.the-tagline img {
		width: 80px;
		margin-left: 10px;
	}

	.banner .caption .search-box .the-title {
		font-size: 26px;
		line-height: 26px;
		margin-bottom: 10px;
	}

	.banner .caption .search-box form input[type='text'] {
		padding: 0px 15px;
		font-size: 14px;
	}
	.banner .caption .search-box form input[type='submit'] {
		width: auto;
		text-indent: -9999px;
		background: url(../../img/icon-search.svg) center center no-repeat $red;
		background-size: 22px auto;
		width: 40px;
	}

	.banner.inner img.bg {
		height: 250px;
	}
}
// mq_down_sm
