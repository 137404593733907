// Define Sizes
$xs: 0;
$sm: 576px;
$md: 993px; // use 993px instead if needed
$lg: 1025px;
$xl: 1200px;

// Mobile FIrst
@mixin mq_up($screen_width) {
	@media only screen and (min-width : #{$screen_width}) {
		@content;
	}
}

// Desktop First
@mixin mq_down($screen_width) {
	@media only screen and (max-width : #{($screen_width - 1)}) {
		@content;
	}
}

// Range Specific
@mixin mq($from, $to) {
	@media (min-width: #{$from}) and (max-width: #{$to}) {
		@content;
	}
}
