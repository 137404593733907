.content.member-page {
	.form-group {
		margin-bottom: 70px;
		label {
			color: $red;
			font-family: $font2;
			margin-bottom: 20px;
			font-size: 24px;
		}
		.form-control {
			border-top: none;
			border-left: none;
			border-right: none;
			border-bottom: 1px solid $orange;
			padding-left: 0px;
			padding-right: 0px;
		}
	}

	.captcha-box {
		margin-bottom: 40px;
	}

	.btn-submit {
		width: 100%;
		max-width: 320px;
		margin: 0px auto;
		display: block;
		height: 60px;
		line-height: 60px;
	}

	.forgot-password-link {
		margin-top: 40px;
	}
}

@include mq_down($sm) {
	.content.member-page .form-group {
		margin-bottom: 40px;
	}
	.content.member-page .form-group label {
		margin-bottom: 10px;
	}
}
// mq_down_sm
