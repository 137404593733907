.relative {
	position: relative;
	z-index: 0;
}

.overflow {
	overflow: hidden;
}

.no-gutters {
	margin-right: 0;
	margin-left: 0;

	> .col,
	> [class*='col-'] {
		padding-right: 0;
		padding-left: 0;
	}
}

.valign {
	@include vertical-align;
}
.halign {
	@include horizontal-align;
}
.vhalign {
	@include vertical-horizontal-align;
}

.reset-button {
	@include reset_button;
}

.reset-ul {
	@include reset_ul;
}

.reset-ul-block {
	@include reset_ul_block;
}

.the-carousel {
	display: none;
	position: relative;
	.the-slide {
		position: relative;
	}

	.slick-arrow {
		@include vertical-align;
		z-index: 2;
		cursor: pointer;
		&.slick-prev {
			left: 0px;
		}
		&.slick-next {
			left: auto;
			right: 0px;
		}
	}

	ul.slick-dots {
		@include reset_ul;
		@include flex-center;
		li {
			button {
				cursor: pointer;
			}

			&.slick-active {
				button {
					color: red;
				}
			}
		}
	}

	&.slick-initialized {
		display: block;
	}
}

.section-title {
	margin-bottom: 12px;
	position: relative;
	font-family: $font2;
	span {
		color: #fff;
		display: inline-block;
		letter-spacing: 1px;
		margin-bottom: 8px;
		font-size: 38px;
		padding: 8px 20px 2px 20px;
	}
	.text1 {
		background-color: $orange;
	}
	.text2 {
		background-color: $red;
	}
}

@include mq_down($md) {
	.section-title {
		margin-bottom: 12px;
		span {
			font-size: 32px;
			padding: 10px 15px 6px 15px;
		}
	}
}
// mq_down_md

@include mq_down($sm) {
	.section-title {
		margin-bottom: 12px;
		span {
			font-size: 28px;
			padding: 6px 10px 4px 10px;
		}
	}
}
// mq_down_sm
