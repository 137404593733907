$primary_color: #373737;
$purple: #413957;
$red: #b20000;
$orange: #ff6f24;

$heading-margin: 1.4rem;
$content-margin: 1.4rem;
$img-margin: 1.4rem;

$colors: (
	'primary_color': $primary_color,
	'purple': $purple,
	'red': $red,
	'orange': $orange,
);

@each $name, $color in $colors {
	.bg-#{$name} {
		background-color: $color;
	}
	.text-#{$name} {
		color: $color;
	}
	.border-#{$name} {
		border-color: $color;
	}
}
