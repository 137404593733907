.red-box-search-result {
	padding: 45px 0px;
	background-color: $red;
	margin-bottom: 50px;
	.search-for {
		font-size: 30px;
		font-weight: $fw-light;
		label {
			font-family: $font2;
			font-size: 30px;
			line-height: 1;
			text-transform: uppercase;
			color: #fff;
			margin-bottom: 0px;
		}
	}
	.search-counts {
		font-size: 30px;
		font-weight: $fw-light;
		a {
			font-family: $font2;
			font-size: 30px;
			line-height: 1;
			text-transform: uppercase;
			color: #fff;
			text-decoration: none;
			&:hover {
				opacity: 0.8;
			}
		}
	}
}

.content.search-result {
	margin-top: -90px;
	.the-result-box {
		padding: 40px 0px;
		.the-header {
			margin-bottom: 30px;
			.section-title {
				margin-bottom: 0px;
				text-decoration: none;
				span {
					letter-spacing: 1px;
					font-size: 26px;
					padding: 6px 12px 2px 12px;

					&.text2 {
						margin-bottom: 0px;
					}
				}

				&:hover {
					opacity: 0.8;
				}
			}
		}

		a.see-job {
			color: $red;
			text-decoration: none;
			font-size: 18px;
			font-weight: $fw-semibold;
			span {
				color: $orange;
			}

			&:hover {
				opacity: 0.8;
			}
		}
		.the-content {
		}

		&:first-child {
			margin-top: -40px;
		}
		&:nth-child(even) {
			background-color: #f6f4f4;
			position: relative;
			&::before {
				content: '';
				display: block;
				position: absolute;
				top: 0px;
				bottom: 0px;
				width: 100vw;
				right: 100%;
				background-color: #f6f4f4;
			}
			&::after {
				content: '';
				display: block;
				position: absolute;
				top: 0px;
				bottom: 0px;
				width: 100vw;
				left: 100%;
				background-color: #f6f4f4;
			}
		}
	}

	.pagination-wrapper {
		margin-top: 30px;
		ul.the-pagination {
			@include reset_ul;
			@include flex-start;
			li {
				margin-right: 20px;
				a {
					font-size: 20px;
					text-decoration: none;
					color: $primary_color;
					font-weight: $fw-semibold;
					border: none;
					border-radius: 0px;
				}

				&:hover,
				&.active {
					a {
						background-color: $red;
						color: #fff;
					}
				}

				&:last-child {
					margin-right: 0px;
				}
			}
		}
	}

	.job-locations {
		margin-top: 50px;
		border-top: 1px solid #000;
		padding-top: 50px;

		.the-title {
			font-family: $font2;
			text-transform: uppercase;
			font-size: 30px;
			margin-bottom: 30px;
		}
		ul {
			@include reset_ul;
			@include flex-start;
			flex-wrap: wrap;
			li {
				width: 25%;
				margin-bottom: 5px;
				a {
					color: $primary_color;
					text-decoration: none;

					&:hover {
						color: $red;
					}
				}
			}
		}
	}
}

@include mq_down($md) {
	.red-box-search-result {
		padding: 30px 0px;
	}

	.red-box-search-result .search-for,
	.red-box-search-result .search-counts {
		font-size: 20px;
		line-height: 1;
	}

	.content.search-result {
		margin-top: -50px;
	}
	.content.search-result .job-locations ul li {
		width: 33.33%;
	}
}
// mq_down_md

@include mq_down($sm) {
	.red-box-search-result {
		padding: 10px 0px;
		text-align: center;
	}

	.red-box-search-result .search-for {
		margin-bottom: 15px;
		font-size: 20px;
	}
	.red-box-search-result .search-for label {
		display: block;
		font-size: 20px;
	}
	.red-box-search-result .search-for,
	.red-box-search-result .search-counts,
	.red-box-search-result .search-counts a {
		font-size: 20px;
	}

	.content.search-result {
		margin-top: -50px;
	}
	.content.search-result .the-result-box a.see-job {
		margin-top: 15px;
		display: block;
	}

	.content.search-result .the-result-box .the-header .section-title span {
		font-size: 22px;
	}

	.content.search-result .job-locations ul li {
		width: 50%;
	}
}
// mq_down_sm
