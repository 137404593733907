$font1: 'Nunito Sans', sans-serif;
$font2: 'Bebas Neue', cursive;

$fw-thin: 100;
$fw-extralight: 200;
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semibold: 600;
$fw-bold: 700;
$fw-extrabold: 800;
$fw-black: 900;

.font1 {
	font-family: $font1;
}
.font2 {
	font-family: $font2;
}
.fw-thin {
	font-weight: $fw-thin;
}
.fw-extralight {
	font-weight: $fw-extralight;
}
.fw-regular {
	font-weight: $fw-regular;
}
.fw-medium {
	font-weight: $fw-medium;
}
.fw-semibold {
	font-weight: $fw-semibold;
}
.fw-bold {
	font-weight: $fw-bold;
}
.fw-extrabold {
	font-weight: $fw-extrabold;
}
.fw-black {
	font-weight: $fw-black;
}
