.intro-box {
	padding: 60px 0px;
	font-size: 18px;
	line-height: 32px;
	color: $purple;
	p {
		&:last-child {
			margin-bottom: 0px;
		}
	}
}

@include mq_down($sm) {
	.intro-box {
		font-size: 16px;
		line-height: 26px;
		padding: 40px 0px;
	}
}
// mq_down_sm
