.sub-navigation {
	padding: 45px 0px;
	background-color: $red;
	margin-top: -90px;
	margin-bottom: 50px;
	ul {
		@include reset_ul;
		@include flex-space-between;
		li {
			a {
				color: #fff;
				text-decoration: none;
				font-family: $font2;
				text-transform: uppercase;
				font-size: 24px;
				display: block;
			}

			&:hover,
			&.active {
				a {
					position: relative;

					&::before {
						/* font-family: $font1; */
						font-size: 22px;
						content: '>';
						display: block;
						position: absolute;
						left: -15px;
						top: 2px;
					}
				}
			}
		}
	}
}

.cv-advice {
	.row {
		margin-bottom: 50px;

		&:last-child {
			margin-bottom: 0px;
		}
	}
}

@include mq_down($md) {
	.sub-navigation {
		margin-top: -50px;
		padding: 30px 0px;
		ul {
			li {
				a {
					font-size: 20px;
				}
			}
		}
	}
	.sub-navigation ul li:hover a::before,
	.sub-navigation ul li.active a::before {
		top: 0px;
	}
}
// mq_down_md

@include mq_down($sm) {
	.sub-navigation {
		margin-top: -50px;
		padding: 10px 0px;
		ul {
			li {
				width: 50%;
				margin: 10px 0px;
				a {
					font-size: 20px;
				}
			}
		}
	}
	.sub-navigation ul li:hover a::before,
	.sub-navigation ul li.active a::before {
		top: 0px;
	}
}
// mq_down_sm
