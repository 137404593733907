.employer-of-the-week {
	padding: 50px 0px 60px 0px;

	.the-carousel {
		.the-slide {
			text-align: center;
			padding: 30px 0px;

			a.the-thumb {
				display: flex;
				align-items: center;
				justify-content: center;
				text-decoration: none;
				width: 220px;
				height: 220px;
				border-radius: 50%;
				background-color: #fff;
				box-shadow: 1px 1px 10px rgba($red, 0.15);
				margin: 0px auto;

				img {
					width: 70%;
					max-height: 70%;
				}

				&:hover {
					box-shadow: 1px 1px 10px rgba($red, 0.3);
				}
			}
			span.the-title {
				display: flex;
				justify-content: center;
				font-size: 24px;
				line-height: 28px;
				color: $red;
				width: 200px;
				height: 56px;
				margin: 0px auto;
			}

			&.text-down {
				//padding-top: 136px;
				span.the-title {
					margin-top: 60px;
					position: relative;
					&::after {
						content: '';
						display: block;
						width: 1px;
						height: 40px;
						margin-top: -50px;
						border-left: 2px dashed #404040;
						padding: auto;
						position: absolute;
						@include horizontal-align;
						top: 0px;
					}
				}
			}

			&.text-up {
				padding-bottom: 136px;
				span.the-title {
					margin-bottom: 60px;
					position: relative;
					align-items: flex-end;

					&::after {
						content: '';
						display: block;
						width: 1px;
						height: 40px;
						margin-bottom: -50px;
						border-left: 2px dashed #404040;
						padding: auto;
						position: absolute;
						@include horizontal-align;
						bottom: 0px;
					}
				}
			}
		}
	}
}

@include mq_down($lg) {
	.employer-of-the-week .the-carousel .the-slide a.the-thumb {
		width: 200px;
		height: 200px;
	}
}
// mq_down_lg

@include mq_down($sm) {
	/* .employer-of-the-week {
		padding-top: 0px;
	} */
	.employer-of-the-week .the-carousel .the-slide.text-up {
		padding-bottom: 10px;
	}
	.employer-of-the-week .the-carousel .the-slide.text-down {
		padding-top: 10px;
	}
}
// mq_down_sm
