.jobs-by-location {
	padding: 50px 0px 50px 0px;
	position: relative;
	overflow: hidden;
	img.bg {
		position: absolute;
		z-index: 0;
		top: 0px;
		height: 100%;
		width: 100%;
		max-width: 1920px;
		object-fit: cover;
		object-position: top;
		@include horizontal-align;
	}

	.bg-elements {
		position: absolute;
		z-index: 0;
		top: 0px;
		left: 0px;
		bottom: 0px;
		width: 100%;
		background: url(../../img/jobs-by-location/bg-elements.png) center center
			no-repeat;
	}

	.the-carousel {
		border-bottom: 1px solid $red;
		margin-bottom: 60px;
		padding-bottom: 30px;
		.the-slide {
			padding: 25px 0px;
			a.the-job {
				font-size: 24px;
				line-height: 32px;
				font-weight: $fw-bold;
				color: $primary_color;
				text-decoration: none;
				display: block;
				span {
					font-size: 20px;
					line-height: 24px;
					font-style: italic;
					font-weight: normal;
					color: $orange;
					display: block;
				}

				&:hover {
					color: #000;
				}
			}
		}
	}

	.search-form {
		position: relative;
		z-index: 1;

		label {
			font-size: 30px;
			line-height: 1;
			width: 100px;
			margin-right: 15px;
		}

		.form-control {
			flex: 1;
			margin-right: 48px;
		}

		.the-button {
			width: 300px;
		}
	}
}

@include mq_down($md) {
	.jobs-by-location .the-carousel .the-slide {
		padding: 0px;
		.col-lg-3 {
			margin-bottom: 25px;
		}
	}
	.jobs-by-location .search-form .the-button {
		width: auto;
	}
	.jobs-by-location .search-form label {
		font-size: 24px;
		width: 80px;
	}
	.jobs-by-location .search-form .form-control {
		margin-right: 20px;
	}
	.jobs-by-location .the-carousel .the-slide a.the-job {
		font-size: 22px;
		line-height: 30px;
	}
}
// mq_down_md

@include mq_down($sm) {
	.jobs-by-location .the-carousel .the-slide a.the-job {
		font-size: 16px;
		line-height: 26px;
	}
	.jobs-by-location .the-carousel {
		margin-bottom: 30px;
		padding-bottom: 20px;
	}

	.jobs-by-location .search-form .the-button {
		width: 100%;
		margin-top: 10px;
	}
	.jobs-by-location .search-form label {
		font-size: 20px;
		width: auto;
		margin-right: 0px;
	}
	.jobs-by-location .search-form .form-control {
		margin-right: 0px;
		margin-left: 0px;
	}
}
// mq_down_sm
