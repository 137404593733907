.job-alerts {
	padding: 44px 0px;
	background-color: $red;
	position: relative;
	overflow: hidden;

	.bg-elements {
		position: absolute;
		z-index: 0;
		top: 0px;
		left: 0px;
		bottom: 0px;
		width: 100%;
		background: url(../../img/job-alerts/bg-elements.png) center center
			no-repeat;
	}

	.section-title {
		margin-bottom: 0px;
		span.text-1 {
			background-color: #fff;
			color: $primary_color;

			&:last-child {
				margin-bottom: 0px;
				width: 220px;
			}
		}
	}

	input[type='text'] {
		height: 40px;
		border: none;
		border-bottom: 1px solid #fff;
		background: transparent;
		flex: 1;
		font-size: 24px;
		color: #fff;
		padding-bottom: 10px;
		&::placeholder {
			color: #c7bae3;
			font-style: italic;
		}
	}
	input[type='submit'] {
		@include reset_button;
		background: transparent;
		font-size: 40px;
		font-family: $font2;
		color: #fff;
		margin-left: 58px;

		&:hover {
			opacity: 0.8;
		}
	}
}

@include mq_down($md) {
	.job-alerts .section-title span.text-1:last-child {
		width: auto;
	}
	.job-alerts input[type='text'] {
		font-size: 20px;
	}
	.job-alerts input[type='submit'] {
		margin-left: 30px;
	}
}
// mq_down_md

@include mq_down($md) {
	.job-alerts .section-title {
		margin-bottom: 15px;
	}
	.job-alerts input[type='text'] {
		font-size: 16px;
	}
	.job-alerts input[type='submit'] {
		margin-left: 15px;
	}
	.job-alerts input[type='submit'] {
		font-size: 22px;
	}
}
// mq_down_sm
