header {
	height: 130px;
	background-color: #fff;
	position: absolute;
	z-index: 5;
	top: 50px;
	left: 0px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	a.logo {
		display: block;
		text-decoration: none;
		width: 200px;
		img {
			width: 100%;
		}
	}

	ul.main-menu {
		@include reset_ul;
		@include flex-end;
		li {
			margin-left: 90px;
			a {
				font-family: $font2;
				text-transform: uppercase;
				color: $purple;
				font-size: 24px;
				text-decoration: none;

				&:hover {
					color: $red;
				}
			}
		}
	}

	.menu-open {
		@include reset_button;
		.hamburger-label {
			display: inline-block;
			vertical-align: middle;
			margin-top: -10px;
			font-size: 18px;
			color: $red;
			margin-left: 5px;
		}
	}
}

@include mq_down($md) {
	header {
		height: auto;
		top: 30px;
		padding: 10px 0px;
		a.logo {
			width: auto;
			img {
				height: 70px;
				width: auto;
			}
		}
	}
}
// mq_down_md
