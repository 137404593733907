.content.recruiters {
	.intro-text {
		padding-bottom: 50px;
	}

	.packages-wrapper {
		position: relative;
		padding-top: 30px;
		img.bg {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		.col-md-6 {
			margin-bottom: 30px;
		}
		.the-package {
			background-color: #fff;
			box-shadow: 1px 1px 10px rgba(#000, 0.6);
			padding: 40px 30px 40px 15px;
			height: 100%;
			.col-md-6 {
				margin-bottom: 0px;
			}
			.the-title {
				font-family: $font2;
				font-size: 22px;
				margin-bottom: 30px;
				span.text1 {
					color: #fff;
					text-transform: uppercase;
					display: inline-block;
					padding-right: 20px;
					background-color: $orange;
					margin-bottom: 10px;
					position: relative;
					&::before {
						content: '';
						display: block;
						position: absolute;
						top: 0px;
						bottom: 0px;
						right: 100%;
						width: 15px;
						background-color: $orange;
					}
				}
				span.text2 {
					color: #fff;
					text-transform: uppercase;
					display: inline-block;
					padding-right: 20px;
					background-color: $red;
					position: relative;
					&::before {
						content: '';
						display: block;
						position: absolute;
						top: 0px;
						bottom: 0px;
						right: 100%;
						width: 15px;
						background-color: $red;
					}
				}
			}
			.price-wrapper {
				display: inline-block;
				.the-price {
					font-size: 52px;
					line-height: 52px;
					font-family: $font2;
					position: relative;
					border-bottom: 1px solid $red;
					display: inline-block;
					margin-bottom: 20px;

					span {
						font-family: $font1;
						font-size: 24px;
						line-height: 24px;
						display: inline-block;
						vertical-align: top;
						margin-top: 2px;
						margin-right: 5px;
					}
				}
				a.book-now {
					display: block;
					text-decoration: none;
					color: $red;
					text-transform: uppercase;
					font-family: $font2;
					font-size: 24px;
					line-height: 24px;
				}
			}

			p {
				font-size: 16px;
				padding-right: 30px;
				&:last-child {
					margin-bottom: 0px;
				}
			}

			ul {
				font-size: 16px;
				padding-left: 15px;
			}
		}
	}

	.red-box {
		padding: 50px 0px 50px 0px;
		font-size: 18px;
		p {
			margin-bottom: 30px;
			&:last-child {
				margin-bottom: 0px;
			}
		}
	}

	.top-recruiters {
		padding: 70px 0px 0px 0px;

		.the-carousel {
			.the-slide {
				text-align: center;
				padding: 30px 0px;
				a.the-logo {
					display: flex;
					align-items: center;
					justify-content: center;
					text-decoration: none;
					width: 220px;
					height: 220px;
					border-radius: 50%;
					background-color: #fff;
					box-shadow: 1px 1px 10px rgba($red, 0.15);
					margin: 0px auto;

					img {
						width: 70%;
						max-height: 70%;
					}

					span {
						font-size: 20px;
						font-weight: $fw-bold;
						text-transform: uppercase;
						color: $orange;
						padding: 10px;
						display: block;
					}

					&:hover {
						box-shadow: 1px 1px 10px rgba($red, 0.3);
					}
				}
			}
		}
	}

	.ccs-section {
		padding-top: 70px;
		.the-header {
			margin-bottom: 50px;
			.section-title {
				margin-bottom: 0;
				.text2 {
					margin-bottom: 0px;
				}
			}
			.ccs-logos {
				img {
					margin-left: 30px;
				}
			}
		}

		.the-content {
			font-size: 18px;

			p:last-child {
				margin-bottom: 0px;
			}
		}
	}
}

@include mq_down($lg) {
	.content.recruiters .top-recruiters .the-carousel .the-slide a.the-logo {
		width: 200px;
		height: 200px;
	}
}
// mq_down_lg

@include mq_down($md) {
	.content.recruiters .packages-wrapper .the-package p {
		padding-right: 0px;
	}
	.content.recruiters .packages-wrapper .the-package .price-wrapper {
		margin-bottom: 30px;
	}
	.content.recruiters .packages-wrapper .the-package p {
		font-size: 14px;
	}
	.content.recruiters .packages-wrapper .the-package .price-wrapper .the-price {
		font-size: 40px;
		line-height: 40px;
	}

	.content.recruiters .ccs-section .the-header .ccs-logos {
		padding-top: 30px;
	}
	.content.recruiters .ccs-section .the-header .ccs-logos img {
		margin-left: 0px;
		margin-right: 30px;
	}
}
// mq_down_md
