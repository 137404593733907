.content.company {
	.four-boxes {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		position: relative;
		margin-bottom: 70px;
		img.center-image {
			position: absolute;
			@include vertical-horizontal-align;
			width: 440px;
			height: 440px;
			border-radius: 50%;
			object-fit: cover;
			border: 2px solid #fff;
			box-shadow: 1px 1px 15px rgba(#000, 0.3);
		}

		.the-box {
			position: relative;
			z-index: 1;
			border: 2px solid $orange;
			padding: 30px;
			width: 380px;
			height: 248px;
			background-color: rgba(#fff, 0.65);
			display: flex;
			align-items: center;
			justify-content: center;

			&:nth-child(1),
			&:nth-child(2) {
				margin-bottom: 110px;
			}
		}
	}

	.platforms {
		margin-bottom: -30px;
		.section-title {
			margin-bottom: 30px;
		}
		a.the-platform {
			display: block;
			text-decoration: none;
			border: 1px solid $orange;
			height: 120px;
			display: flex;
			align-items: center;
			justify-content: center;
			img {
				padding: 15px;
				max-width: 90%;
			}

			&:hover {
				opacity: 0.8;
			}
		}

		.col-6 {
			margin-bottom: 30px;
		}
	}
}

@include mq_down($md) {
	.content.company .four-boxes .the-box {
		padding: 20px;
		width: 300px;
		height: 300px;
	}
	.content.company .four-boxes .the-box:nth-child(1),
	.content.company .four-boxes .the-box:nth-child(2) {
		margin-bottom: 110px;
	}
}
// mq_down_md

@include mq_down($sm) {
	.content.company .four-boxes {
		display: block;
	}
	.content.company .four-boxes .the-box {
		padding: 20px;
		width: 100%;
		height: auto;
		margin-bottom: 30px;
	}
	.content.company .four-boxes .the-box:nth-child(1),
	.content.company .four-boxes .the-box:nth-child(2) {
		margin-bottom: 30px;
	}

	.content.company .four-boxes img.center-image {
		width: 200px;
		height: 200px;
		position: relative;
		transform: none;
		margin: 0px auto 30px auto;
		display: block;
	}
}
// mq_down_sm
