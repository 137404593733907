.content {
	padding-bottom: 100px;
	position: relative;

	.bg-elements {
		position: absolute;
		z-index: -1;
		top: 0px;
		left: 0px;
		bottom: 0px;
		width: 100%;
		background: url(../../img/bg-elements.png) center top no-repeat;
	}
}

@include mq_down($md) {
	.content {
		padding-bottom: 50px;
	}
}
// mq_down_md
