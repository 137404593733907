.typography {
	h1 {
		margin-bottom: $heading-margin;
		font-family: $font1;
	}
	h2 {
		margin-bottom: $heading-margin;
		font-family: $font1;
	}
	h3 {
		margin-bottom: $heading-margin;
		font-family: $font1;
		color: $red;
	}
	h4 {
		margin-bottom: $heading-margin;
		font-family: $font1;
		font-weight: $fw-bold;
	}
	h5 {
		margin-bottom: $heading-margin;
		font-family: $font1;
	}
	h6 {
		margin-bottom: $heading-margin;
		font-family: $font1;
	}
	.section-title {
		font-family: $font2;
	}
	.alignnone {
		margin: 0px 0px $img-margin 0px;
	}
	.aligncenter,
	div.aligncenter {
		display: block;
		margin: 0px auto $img-margin auto;
	}
	.alignright {
		float: right;
		margin: 0px 0px $img-margin $img-margin;
	}
	.alignleft {
		float: left;
		margin: 0px $img-margin $img-margin 0px;
	}
	a img.alignright {
		float: right;
		margin: 0px 0px $img-margin $img-margin;
	}
	a img.alignnone {
		margin: 0px 0px $img-margin 0px;
	}
	a img.alignleft {
		float: left;
		margin: 0px $img-margin $img-margin 0px;
	}
	a img.aligncenter {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	img {
		height: auto;
	}

	ul,
	ol {
		margin: 0px 0px $content-margin 0px;
		padding: 0px 0px 0px 25px;
		overflow: hidden;

		& + h1,
		& + h2,
		& + h3,
		& + h4,
		& + h5 {
			padding-top: 20px;
		}
	}

	a {
		color: $red;
		text-decoration: none;
	}
	a:hover {
		text-decoration: underline;
	}

	p {
		margin: 0px 0px $content-margin 0px;

		& + h1,
		& + h2,
		& + h3,
		& + h4,
		& + h5 {
			padding-top: 20px;
		}
	}

	blockquote {
		& + h1,
		& + h2,
		& + h3,
		& + h4,
		& + h5 {
			padding-top: 20px;
		}
	}

	ul.dos-donts {
		@include reset_ul_block;

		li {
			margin-bottom: 5px;
			position: relative;
			padding-left: 20px;
			&::before {
				content: '';
				display: block;
				width: 8px;
				height: 8px;
				background-color: #7eb200;
				border-radius: 50%;
				position: absolute;
				left: 0px;
				top: 8px;
			}
			&.dont {
				&::before {
					background-color: #b20000;
				}
			}
		}
	}

	.orange-box {
		background-color: $orange;
		color: #fff;
		padding: 45px;
		position: relative;
		margin-top: 60px;
		margin-bottom: 50px;
		.red-line {
			position: absolute;
			left: 0px;
			top: 0px;
			width: 100%;
			height: 100%;
			border: 2px solid red;
			transform: rotate(6deg);
		}

		p {
			font-size: 18px;
			line-height: 24px;
			position: relative;
			z-index: 1;
			&:last-child {
				margin-bottom: 0px;
			}
		}
	}
}

@include mq_down($md) {
	.typography .orange-box {
		padding: 35px;
		p {
			font-size: 16px;
		}
	}
}
// mq_down_md
