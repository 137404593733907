.top-employers {
	padding: 50px 0px 50px 0px;
	position: relative;
	img.bg {
		position: absolute;
		z-index: 0;
		top: 0px;
		height: 100%;
		width: 100%;
		max-width: 1140px;
		object-fit: cover;
		object-position: center;
		@include horizontal-align;
	}

	.bg-elements {
		position: absolute;
		z-index: 0;
		top: 0px;
		left: 0px;
		bottom: 0px;
		width: 100%;
		background: url(../../img/top-employers/bg-elements.png) center center
			no-repeat;
	}

	.the-carousel {
		margin-left: -15px;
		margin-right: -15px;
		.the-slide {
			padding: 0px 15px;
			a {
				display: flex;
				align-items: center;
				justify-content: center;
				text-decoration: none;
				height: 190px;
				border: 1px solid #c7c7c7;
				background-color: #fff;
				img {
					max-width: 80%;
					max-height: 80%;
				}

				&:hover {
					border: 1px solid #7a7a7a;
				}
			}
		}
	}
}
