.mobile-menu-wrapper {
	position: fixed;
	top: 0;
	left: 0px;
	width: 100%;
	height: 100vh;
	overflow-y: scroll;
	z-index: 20;
	background-color: #fff;

	transform: translateX(-100%);
	box-shadow: 1px 0px 5px rgba(#000, 0.2);
	@include hidden;
	@include transition;
	display: none;

	.wrapper {
		padding: 15px 15px 30px 15px;
		position: relative;

		.menu-close {
			@include reset_button;
			position: absolute;
			z-index: 1;
			right: 10px;
			top: 15px;
		}

		a.logo {
			display: block;
			text-decoration: none;
			width: 125px;
			margin: 0px auto 20px auto;
		}

		ul.main-menu {
			@include reset_ul_block;
			margin-bottom: 30px;
			li {
				margin-bottom: 15px;
				a {
					display: block;
					text-decoration: none;
					position: relative;
					span {
						display: flex;
						align-items: center;
						position: absolute;
						z-index: 1;
						left: 0px;
						right: 0px;
						bottom: 0px;
						top: 0px;
						padding: 0px 20px;
						background-color: rgba(#000, 0.4);
						text-transform: uppercase;
						font-size: 20px;
						font-weight: $fw-extrabold;
						color: #fff;
						text-shadow: 1px 1px 5px rgba(#000, 0.3);
					}
					img {
						height: 100px;
						width: 100%;
						object-fit: cover;
					}
				}
			}
		}

		ul.member-links {
			@include reset_ul;
			@include flex-center;
			li {
				margin-left: 30px;
				a {
					font-size: 1.1rem;
					font-weight: $fw-bold;
					color: $primary_color;
					text-decoration: none;

					&.login-link {
						img {
							display: inline-block;
							vertical-align: middle;
							margin-top: -3px;
							margin-left: 4px;
						}
					}
					&.register-link {
					}
				}

				&:first-child {
					margin-left: 0px;
				}
			}
		}
	}

	&.active {
		@include visible;
		transform: translateX(0%);
	}

	&.initialized {
		display: block;
	}
}

body {
	position: relative;
}
body.noscroll {
	overflow: hidden;
}
